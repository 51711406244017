<template>
    <div  :style="{height:bodyHeight+'px'}" style="overflow: hidden; position: relative;">
        <div id="container" style="width: 100%;height: 100%; z-index: 1;"></div>

        <monitor-resources :resourceHeight="resourceHeight" @cameraClick="cameraClick" @resourcesChangeRefreshMap="resourcesChangeRefreshMap" @resourcesAllChangeRefreshMap="resourcesAllChangeRefreshMap" @changeCurrentIndustry="changeCurrentIndustry"/>
        <monitor-event :eventHeight="eventHeight" @createEventMarker="createEventMarker" @createVideoEventMarker="createVideoEventMarker" @positioningEvent="positioningEvent" @positioningVideoEvent="positioningVideoEvent" ref="monitorEvent"/>
        <monitor-video-statistics />
        <!--    <monitor-access-statistics></monitor-access-statistics>-->
        <monitor-camera-video-play ref="monitorCameraVideoPlay" :select-play-camera="selectPlayCamera" v-if="isShowCameraVideo"
                                   @cameraPlayClose="cameraVideoCloseClick"
                                   @cameraVideoCloseButFourScreenClick="cameraVideoCloseButFourScreenClick"
                                   @cameraVideoCloseButNineScreenClick="cameraVideoCloseButNineScreenClick"/>
        <four-screen ref="fourScreen" @oneScreen="oneScreen" @onClose="()=>{this.fromFour = false;this.fromNine = false}"/>
        <nine-screen ref="nineScreen" @nine2oneScreen="nine2oneScreen" @onClose="()=>{this.fromNine = false;this.fromFour = false}"/>
        <nine-screen-tour ref="nineScreenTour" />
        <video-event-detail ref="videoEventDetail" @getNearbyCameras="getNearbyCameras"></video-event-detail>
        <monitor-map-layer-control @layerSelectClick="layerSelectClick" />
        <monitor-map-camera-legend ref="monitorMapCameraLegend" v-if="currentIndustry === INDUSTRY_TYPE.高速公路.value || currentIndustry === 'search'" @legendSelectClick="legendSelectClick" />
    </div>
</template>

<script>
    import Vue from 'vue/dist/vue.js'
    import V from 'vue'
    import ampUtil from '../../utils/ampUtil'
    import { getExistNavigatorBodyHeignt } from '../../core/bodyHeigntUtil'
    import monitorResources from "../../components/MonitorResources"
    import monitorVideoStatistics from "../../components/MonitorVideoStatistics"
    // import monitorAccessStatistics from "../../components/MonitorAccessStatistics"
    import monitorCameraVideoPlay from "../../components/monitor/MonitorCameraVideoPlay"
    import monitorMapLayerControl from "../../components/monitor/MonitorMapLayerControl"
    import monitorMapCameraLegend from "../../components/monitor/MonitorMapCameraLegend"
    import { getCameras } from '@/api/camera'
    import { getAllEtcs } from '@/api/etc'
    import { getAllTollStation } from '@/api/tollStation'
    import { INDUSTRY_TYPE, RESOURCE_TYPE, EVENT_STATUS, DEPARTMENT_QUERY_TYPE } from '../../core/enums'
    import MonitorEvent from "../../components/MonitorEvent"
    import MonitorEventDialog from "../../components/monitor/MonitorEventDialog"
    import { ROAD_SIGNS } from "../../assets/images/map/roadSign"
    import FourScreen from "../../components/monitor/monitorCameraVideoPlay/FourScreen"
    import NineScreen from "../../components/monitor/monitorCameraVideoPlay/NineScreen"
    import NineScreenTour from "../../components/monitor/monitorCameraVideoPlay/NineScreenTour"
    import VideoEventDetail from "../../components/monitor/VideoEventDetail"

    //摄像机图片
    let camera_status_offline_image = require("../../assets/images/map/icon_map_sxj_offline.png")
    let camera_sd_status_online_image = require("../../assets/images/map/icon_map_sxj_sd_online.png")
    let camera_mj_status_online_image = require("../../assets/images/map/icon_map_sxj_mj_online.png")
    let camera_sfz_status_online_image = require("../../assets/images/map/icon_map_sxj_sfz_online.png")
    let camera_wc_status_online_image = require("../../assets/images/map/icon_map_sxj_wc_online.png")
    let camera_fwq_status_online_image = require("../../assets/images/map/icon_map_sxj_fwq_online.png")
    let etc_image = require("../../assets/images/map/icon_map_mj_online.png")
    let event_image = require("../../assets/images/map/icon_map_sj.png")
    let video_event_image = require("../../assets/images/map/icon_map_spsj.png")
    let tollStation_image = require("../../assets/images/map/icon_map_sfz_online.png")
    let camera_image_size = {width:16,height: 16} // 图片尺寸
    let map
    export default {
        name: "MonitorMain",
        components: {
            NineScreenTour,
            NineScreen,
            FourScreen,
            VideoEventDetail,
            MonitorEventDialog,
            MonitorEvent,
            monitorResources,
            monitorVideoStatistics,
            // monitorAccessStatistics,
            monitorCameraVideoPlay,
            monitorMapLayerControl,
            monitorMapCameraLegend
        },
        data(){
            return{
                cameraParam:{
                    roleId: V.ls.get('user').roleIds[0],
                    road: '',
                    areacode: '',
                    department: '甘肃省高速公路局',
                    classify: -1,
                    // 地图可视范围
                    startLng: 0, // 起始经度
                    endLng: 0, // 结束经度
                    startLat: 0, // 起始纬度
                    endLat: 0, // 结束维度
                    zoom:100
                },
                INDUSTRY_TYPE,
                RESOURCE_TYPE,
                EVENT_STATUS,
                DEPARTMENT_QUERY_TYPE,
                bodyHeight:"",
                resourceHeight: 0, // 资源面板高度
                eventHeight: 0, // 事件面板高度
                selectCamera:{},
                selectMarker: null, // 选中的相机特效
                isShowCameraVideo:false,
                trafficLayer:{},
                cameraMarkers: [],
                cameraTypeMarkers: { // 按相机类型,单独控制不同类型相机可见性
                    sd: [],
                    sfz: [],
                    wc: [],
                    mj: [],
                    fwq: []
                },
                etcMarkers: [],
                tollStationMarkers: [],
                eventMarkers: [],
                videoEventMarkers: [],
                // roadSignMarkers: [], // 路标指示牌
                cameraLayerGroup: {},
                cameraTypeLayerGroup: { // 按相机类型,单独控制不同类型相机可见性
                    sd: {},
                    sfz: {},
                    wc: {},
                    mj: {},
                    fwq: {},
                },
                showCamera: true, // 全局记录是否展示相机
                cameraVisible: {  //各种类型相机的可见性
                    sd: true,
                    sfz: true,
                    wc: true,
                    mj: true,
                    fwq: true
                },
                etcLayerGroup: {},
                areaSelectPolygon:{},
                tollStationLayerGroup: {},
                eventLayerGroup: {},
                videoEventLayerGroup: {},
                roadSign: {},
                infoWindow:{},
                mapCenter:[100.634697,37.929996],
                mapDefaultZoom: 6,
                movedByAmap: false, // 视角移动是否是由高德的setFitView函数造成的
                isShowInfoWindow:false,
                infoWindowContent:'',
                selectPlayCamera:{},
                zoomStart: 6, // 滚动时的起始zoom值
                fromFour: false, // 是否是从四分屏切回来的
                fromNine: false, // 是否是从九分屏切回来的
                currentIndustry: '', // 当前选中的行业
                nearbyCameras: [],
            }
        },
        methods:{
            mapCreate() {
                map = ampUtil.createMap('container', this.mapCenter,
                    // 'amap://styles/b49663ed32df732c0cf580fce9d38749')
                    // 'amap://styles/914383f63364635eeaf00f796caf77a0')
                    'amap://styles/50ce9e52628fedb7b8429fd011d23bd2')

                // 限制地图显示范围
                map.setLimitBounds(map.getBounds())
                // 设置鼠标样式
                map.setDefaultCursor('default')

                ampUtil.bindMapEvent(map, 'zoomend', this.mapZoomend)
                ampUtil.bindMapEvent(map, 'zoomstart', this.mapZoomStart)
                ampUtil.bindMapEvent(map, 'movestart', this.mapMoveStart)
                ampUtil.bindMapEvent(map, 'moveend', this.mapMoveEnd)
                this.trafficLayer = ampUtil.createTraffic()
                this.trafficLayer.setMap(map);
                this.trafficLayer.show()
                this.hideMapOtherAreasByGanSu() // 只显示甘肃省地图
                //创建信息窗体
                this.infoWindow = new AMap.InfoWindow({
                    isCustom: true,
                    content:'',
                    offset: new AMap.Pixel(0, -25)
                });
                this.eventInfoWindow = new AMap.InfoWindow({
                    isCustom: true,
                    content:'',
                    offset: new AMap.Pixel(-8, -68)
                });

                // map.on('click', function(ev) {
                //     console.log(ev.lnglat.lng)
                //     console.log(ev.lnglat.lat)
                //     console.log('------------------------')
                // });

            },
            infoWindowClick(){

            },
            mapZoomStart () {
                this.zoomStart = map.getZoom();
            },
            mapZoomend () {
                // let zoom = map.getZoom(); //获取当前地图级别
                // this.cameraParam.zoom = zoom
                // if (this.zoomStart >= 13 && zoom >= 13) {
                //     return
                // }
                // this.clearCameraLayer()
                // this.setBoundsParam()
                // this.getCamera(this.cameraParam,null)
            },
            mapMoveStart () {
                // 是因为高德自适应视角函数触发的地图移动
                // this.movedByAmap = true

            },
            mapMoveEnd () { // 当地图中心点发生变化(缩放和拖拽都可以使用该方法)
                // 小于9级时显示路标指示牌, 大于9级时隐藏路标指示牌
                if (map.getZoom() < 9) {
                    this.roadSignLayerGroup.show()
                } else {
                    this.roadSignLayerGroup.hide()
                }

                // 高德
                if (!this.movedByAmap) {
                    let zoom = map.getZoom(); //获取当前地图级别
                    this.cameraParam.zoom = zoom
                    // if (this.zoomStart >= 13 && zoom >= 13) {
                    //     return
                    // }
                    this.clearCameraLayer()
                    this.setBoundsParam()
                    this.getCamera(this.cameraParam,null)
                }
                this.movedByAmap = false
            },
            hideMapOtherAreasByGanSu() {
                ampUtil.hideMapOtherAreasBySearch('甘肃省',map)
            },
            getNearbyCameras (longitude, latitude, callback) { // 根据经纬度获取附近摄像机
                this.nearbyCameras = []
                let circle = new AMap.Circle({
                    map: map,
                    center: new AMap.LngLat(longitude,latitude),  // 圆心位置
                    radius: 200, // 半径200米内的点
                    fillOpacity: 0,
                    strokeOpacity: 0
                });

                // 查询地图上的设备
                let param = {
                    roleId: V.ls.get('user').roleIds[0],
                    zoom: 100,
                    road: '',
                    areacode: '',
                    department: DEPARTMENT_QUERY_TYPE.全部.value,
                    classify: -1,
                    // startLng: 85.902031,
                    // endLng: 115.367363,
                    // startLat: 29.986922,
                    // endLat: 45.099591,
                    startLng: Number(longitude) - 0.05,
                    endLng: Number(longitude) + 0.05,
                    startLat: Number(latitude) - 0.05,
                    endLat: Number(latitude) + 0.05,
                }

                getCameras(param).then(res => {
                    res.data.forEach(item => {
                        // let point = new AMap.LngLat(item.longitude, item.latitude)
                        let point =  ampUtil.splitlngLat(item.longAndLati)
                        if (circle.contains(point)) {
                            this.nearbyCameras.push(item)
                        }
                    })
                    callback(this.nearbyCameras) // 用于返回数据
                    map.remove(circle);
                })

                // new Promise((resolve, reject) => {
                //     getCameras(param).then(res => {
                //         res.data.forEach(item => {
                //             // let point = new AMap.LngLat(item.longitude, item.latitude)
                //             let point =  ampUtil.splitlngLat(item.longAndLati)
                //             if (circle.contains(point)) {
                //                 result.push(item)
                //             }
                //         })
                //         resolve()
                //     }).catch(error => {
                //         reject(error)
                //     })
                // }).then(() => {
                //     return result
                // })
            },
            getCamera(param,callBack){
                this.clearCameraLayer()
                getCameras(param).then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                        this.createCameraMarker(res.data[i])
                    }
                    // res.data.forEach((camera)=>{
                    //     this.createCameraMarker(camera)
                    // })
                    // this.cameraLayerGroup = this.cameraLayerGroup = ampUtil.createOverlayGroup(this.cameraMarkers)
                    // this.cameraLayerGroup.setMap(map)

                    this.createCameraTypeLayerGroup() // 为不同相机类型创建图层

                    if(callBack!=null){
                        callBack()
                    }
                    this.setCameraVisible()
                    // if (!this.showCamera) {
                    //     this.cameraLayerGroup.hide()
                    // }
                }).finally(() => {
                })
            },
            getEtc(){
                getAllEtcs({}).then((res)=>{
                    res.data.forEach((etc)=>{
                        this.createEtcMarker(etc)
                    })
                    this.etcLayerGroup = ampUtil.createOverlayGroup(this.etcMarkers)
                    this.etcLayerGroup.setMap(map)
                    this.etcLayerGroup.hide()
                })
            },
            getTollStation(){
                getAllTollStation({}).then((res)=>{
                    res.data.forEach((tollStation)=>{
                        this.createTollStationMarker(tollStation)
                    })
                    this.tollStationLayerGroup = ampUtil.createOverlayGroup(this.tollStationMarkers)
                    this.tollStationLayerGroup.setMap(map)
                    this.tollStationLayerGroup.hide()
                })
            },
            pushCameraTypeMarket (classify, marker) {
                if (classify === 1 || classify === 2) { // 外场
                    this.cameraTypeMarkers.wc.push(marker)
                } else if (classify === 7) { // 门架
                    this.cameraTypeMarkers.mj.push(marker)
                } else if (classify === 4 || classify === 5) { // 收费站
                    this.cameraTypeMarkers.sfz.push(marker)
                } else if (classify === 3) { // 隧道
                    this.cameraTypeMarkers.sd.push(marker)
                } else if (classify === 6) { // 服务区
                    this.cameraTypeMarkers.fwq.push(marker)
                }
            },
            createCameraTypeLayerGroup () {
                this.cameraTypeLayerGroup.sd = ampUtil.createOverlayGroup(this.cameraTypeMarkers.sd)
                this.cameraTypeLayerGroup.sd.setMap(map)
                this.cameraTypeLayerGroup.sfz = ampUtil.createOverlayGroup(this.cameraTypeMarkers.sfz)
                this.cameraTypeLayerGroup.sfz.setMap(map)
                this.cameraTypeLayerGroup.wc = ampUtil.createOverlayGroup(this.cameraTypeMarkers.wc)
                this.cameraTypeLayerGroup.wc.setMap(map)
                this.cameraTypeLayerGroup.mj = ampUtil.createOverlayGroup(this.cameraTypeMarkers.mj)
                this.cameraTypeLayerGroup.mj.setMap(map)
                this.cameraTypeLayerGroup.fwq = ampUtil.createOverlayGroup(this.cameraTypeMarkers.fwq)
                this.cameraTypeLayerGroup.fwq.setMap(map)
            },
            createCameraMarker(camera) {
                let position = ampUtil.splitlngLat(camera.longAndLati)
                camera.dataPosition = position
                if(position.length == 2){
                    // 判断图标
                    let image
                    if (camera.lowStreamStatus !== 1) {
                        image = camera_status_offline_image
                    } else {
                        if (camera.classify === 1 || camera.classify === 2) { // 外场
                            image = camera_wc_status_online_image
                        } else if (camera.classify === 7) { // 门架
                            image = camera_mj_status_online_image
                        } else if (camera.classify === 4 || camera.classify === 5) { // 收费站
                            image = camera_sfz_status_online_image
                        } else if (camera.classify === 3) { // 隧道
                            image = camera_sd_status_online_image
                        } else if (camera.classify === 6) { // 服务区
                            image = camera_fwq_status_online_image
                        }
                    }
                    // let image = camera.lowStreamStatus === 1 ? camera_status_online_image : camera_status_offline_image
                    let iconOptions = {
                        size: new AMap.Size(camera_image_size.width, camera_image_size.height),
                        imageSize: new AMap.Size(camera_image_size.width, camera_image_size.height),
                        image: image,  // Icon的图像
                    }
                    let marker = ampUtil.createMarker(camera, position, iconOptions, this.mapCameraMarkerClick, this.mapCameraMarkerMouseover, new AMap.Pixel(-8, -8))
                    this.cameraMarkers.push(marker)

                    // 创建各类相机Market
                    this.pushCameraTypeMarket(camera.classify, marker)
                    // marker.setMap(map);
                }

            },
            createEtcMarker(etc){
                let position = [Number(etc.longitude),Number(etc.latitude)]
                let iconOptions = {
                    size: new AMap.Size(camera_image_size.width, camera_image_size.height),
                    imageSize: new AMap.Size(camera_image_size.width, camera_image_size.height),
                    image: etc_image,  // Icon的图像
                }
                let marker = ampUtil.createMarker(etc, position, iconOptions, this.mapEtcMarkerClick, this.mapEtcMarkerMouseover, new AMap.Pixel(-8, -8))
                this.etcMarkers.push(marker);
            },
            createTollStationMarker(tollStation){
                let position = [Number(tollStation.longitude),Number(tollStation.latitude)]
                let iconOptions = {
                    size: new AMap.Size(camera_image_size.width, camera_image_size.height),
                    imageSize: new AMap.Size(camera_image_size.width, camera_image_size.height),
                    image: tollStation_image,  // Icon的图像
                }
                let marker = ampUtil.createMarker(tollStation, position, iconOptions, this.mapTollStationMarkerClick, this.mapTollStationMarkerMouseover, new AMap.Pixel(-8, -8))
                this.tollStationMarkers.push(marker);
            },
            createEventMarker(events) { // 高德事件撒点
                // 刷新数据时,移除已有的标记
                this.eventMarkers = []
                if (map !== undefined) {
                    map.remove(this.eventLayerGroup)
                }
                // 地图事件标记图层
                events.forEach(event => {
                    // 仅标记进行中的事件
                    if (event.status == EVENT_STATUS.进行中) {
                        let position = [Number(event.start_lng), Number(event.start_lat)]
                        let iconOptions = {
                            size: new AMap.Size(camera_image_size.width, camera_image_size.height),
                            imageSize: new AMap.Size(camera_image_size.width, camera_image_size.height),
                            image: event_image,  // Icon的图像
                        }
                        let marker = ampUtil.createMarker(event, position, iconOptions, this.mapEventMarkerClick, this.mapEventMarkerMouseover, new AMap.Pixel(-8, -8))
                        this.eventMarkers.push(marker);
                    }
                })
                this.eventLayerGroup = ampUtil.createOverlayGroup(this.eventMarkers)
                this.eventLayerGroup.setMap(map)
                // this.eventLayerGroup.hide()

                // 地图上的事件对话框(仅展示进行中的第一个最新事件(未展示过的))
                let processEvent = events.filter(e => e.status == EVENT_STATUS.进行中)
                if (processEvent !== null && events.length > 0 && processEvent.length > 0) {
                    // 展示中的是新事件
                    if (this.$store.latestAlertId !== processEvent[0].alert_id) { // 缓存的最新事件id与此次事件id相同--无新事件
                        this.$store.latestAlertId = processEvent[0].alert_id
                        this.showEventTooltip(processEvent[0])
                    }
                } else {
                    this.eventInfoWindow.close() // 如果没有进行中的事件就关闭对话框(页面放着不动,数据自动刷新时)
                }
            },
            createVideoEventMarker (events) { // 视频事件撒点
                // 刷新数据时,移除已有的标记
                this.videoEventMarkers = []
                if (map !== undefined) {
                    map.remove(this.videoEventLayerGroup)
                }
                // 地图事件标记图层
                for (let i = events.length - 1; i >= 0; i--) {
                    // 仅标记进行中的事件
                    let position = [Number(events[i].longitude), Number(events[i].latitude)]
                    let iconOptions = {
                        size: new AMap.Size(camera_image_size.width, camera_image_size.height),
                        imageSize: new AMap.Size(camera_image_size.width, camera_image_size.height),
                        image: video_event_image,  // Icon的图像
                    }
                    let marker = ampUtil.createMarker(events[i], position, iconOptions, this.mapVideoEventMarkerClick, this.mapVideoEventMarkerMouseover, new AMap.Pixel(-8, -8))
                    this.videoEventMarkers.push(marker);
                }
                this.videoEventLayerGroup = ampUtil.createOverlayGroup(this.videoEventMarkers)
                this.videoEventLayerGroup.setMap(map)
                // this.videoEventLayerGroup.hide()

            },
            createRoadSignMarker(){ // 高速路指示牌
                let roadSignMarkers = []
                ROAD_SIGNS.forEach(sign => {
                    let position = [sign.lng, sign.lat]
                    let iconOptions = {
                        size: new AMap.Size(32, 24),
                        imageSize: new AMap.Size(32, 24),
                        image: sign.src,  // Icon的图像
                    }
                    let marker = ampUtil.createMarker(null, position, iconOptions, null, null, new AMap.Pixel(-24, -18))
                    // map.add(marker)

                    roadSignMarkers.push(marker);

                    this.roadSignLayerGroup = ampUtil.createOverlayGroup(roadSignMarkers)
                    this.roadSignLayerGroup.setMap(map)
                    // this.roadSignLayerGroup.hide()
                })
            },
            mapCameraMarkerMouseover(markerMouseOverEvent){
                // console.log(markerMouseOverEvent)
                this.showCameraTooltip(markerMouseOverEvent.target.dataSource, markerMouseOverEvent.target.dataPosition)
                if (this.selectCamera.cameraNum !== markerMouseOverEvent.target.dataSource.cameraNum) { // 移到其他点上时,移除选中效果
                    this.removeCameraSelectedMarker()
                }
            },
            mapCameraMarkerClick(markerClickEvent){

                // 选中效果
                this.addCameraSelectedMarker(markerClickEvent.target.dataPosition)

                this.selectCamera =  markerClickEvent.target.dataSource
                // this.isShowCameraVideo = true
                let point = markerClickEvent.target.dataPosition

                if (this.$store.fourScreen) { // 四分屏状态,向四分屏中添加视频
                    this.showCameraTooltip(this.selectCamera,point)
                    // let camera = {
                    //     cameraName: markerClickEvent.target.dataSource.cameraName,
                    //     cameraNum: markerClickEvent.target.dataSource.cameraNum
                    // }
                    this.$refs.fourScreen.addVideo(this.selectCamera)
                } else if (this.$store.nineScreen) {// 九分屏状态,向九分屏中添加视频
                    this.showCameraTooltip(this.selectCamera,point)
                    this.$refs.nineScreen.addVideo(this.selectCamera)
                }else { // 普通地图状态,打开视频播放窗口
                    this.openCameraInfoWindow(this.selectCamera,point)
                    // this.showCameraTooltip(this.selectCamera, point)
                    // 播放视频
                    this.$nextTick(() => {
                        this.$refs.monitorCameraVideoPlay.play(this.selectCamera) // 点击相机列表,直接跳出视频播放窗口
                    })
                }
            },
            mapEtcMarkerMouseover(markerMouseOverEvent){
                console.log(markerMouseOverEvent)
            },
            mapTollStationMarkerClick(markerClickEvent){
                //markerClickEvent.target.dataSource
                console.log(markerClickEvent)
            },
            mapTollStationMarkerMouseover(markerMouseOverEvent){
                console.log(markerMouseOverEvent)
            },
            mapEtcMarkerClick(markerClickEvent){
                //markerClickEvent.target.dataSource
                console.log(markerClickEvent)
            },
            mapEventMarkerClick(markerClickEvent){ // 高德事件点击
                // console.log(markerClickEvent)
                this.$refs.monitorEvent.changeEventData(markerClickEvent.target.dataSource)
            },
            mapEventMarkerMouseover(markerMouseOverEvent){ // 高德事件点悬浮事件
                // console.log(markerMouseOverEvent)
                this.showEventTooltip(markerMouseOverEvent.target.dataSource)
            },
            mapVideoEventMarkerClick(markerClickEvent){ // 视频事件点击
                // console.log(markerClickEvent)
                this.$refs.videoEventDetail.open(markerClickEvent.target.dataSource)
            },
            mapVideoEventMarkerMouseover(markerMouseOverEvent){ // 视频事件点悬浮事件
                // 添加相机名称字段
                markerMouseOverEvent.target.dataSource.cameraName = markerMouseOverEvent.target.dataSource.deviceName
                this.showCameraTooltip(markerMouseOverEvent.target.dataSource, markerMouseOverEvent.target.dataPosition)
            },
            addCameraSelectedMarker(position) { // 添加相机选中的特效
                this.removeCameraSelectedMarker()

                // 选中效果
                let div = document.createElement("div");
                div.className='marker-selected';

                this.selectMarker = new AMap.Marker({
                    map:map,
                    // position:new AMap.LngLat(116.373881,39.907409), //基点位置
                    position: position, //基点位置
                    offset: new AMap.Pixel(-14, -14), //相对于基点的偏移位置
                    content:div   //自定义覆盖物内容
                });
            },
            removeCameraSelectedMarker () { // 删除相机选中的特效
                if (this.selectMarker !== null) {
                    map.remove(this.selectMarker)
                }
            },
            cameraClick(camera){
                this.selectCamera = camera
                let point = ampUtil.splitlngLat(camera.longAndLati)
                map.panTo(point, 1)
                if (map.getZoom() < camera.zoom) { // 将地图缩放调整到与该相机相同(现有等级小于才跳转,避免多次查询数据)
                    map.setZoom(camera.zoom)
                }
                this.addCameraSelectedMarker(point)
                this.$nextTick(() => {
                    if (this.$store.fourScreen) { // 四分屏状态,向四分屏中添加视频
                        this.showCameraTooltip(this.selectCamera,point)
                        this.$refs.fourScreen.addVideo(this.selectCamera)
                    } else if (this.$store.nineScreen) { // 九分屏状态,向九分屏中添加视频
                        this.showCameraTooltip(this.selectCamera,point)
                        this.$refs.nineScreen.addVideo(this.selectCamera)
                    } else { // 不是四分屏状态,播放视频
                        this.openCameraInfoWindow(camera,point)
                        this.$nextTick(() => {
                            this.$refs.monitorCameraVideoPlay.play(this.selectCamera) // 点击相机列表,直接跳出视频播放窗口
                        })
                    }
                })
            },
            openCameraInfoWindow(camera,point){
                this.selectPlayCamera = camera
                this.isShowCameraVideo = true
                this.showCameraTooltip(camera,point)
            },
            showCameraTooltip (camera,point) { // 显示相机气泡框
                let that = this
                let MyComponent = Vue.extend({
                    // template: '<div><a v-on:click="clickPlay('+param+')">'+camera.cameraName+'</a></div>',
                    template: `<div @click="detail" style="cursor: pointer; background: rgba(21, 23, 97, 0.85); border: 1px solid #0088FF; padding: 8px 12px; font-size: 12px; font-family: PingFang SC; font-weight: bold; line-height: 17px; color: rgba(255, 255, 255, 0.85); border-radius: 4px;">
                    <span style="position: absolute; top: -1px; right: 4px; color: #0088FF; font-weight: bold;" @click="close($event)">×</span>
                        ${camera.cameraName}
                  </div>`,
                    methods:{
                        close (dom) {
                            dom.target.parentNode.remove()
                            that.removeCameraSelectedMarker()
                        },
                        detail (event) {
                            if (camera.alertType !== undefined) { // 通过字段不同,区分普通相机和视频报警
                                event.stopPropagation()
                                that.$refs.videoEventDetail.open(camera)
                            }
                        }
                    }
                });
                let component = new MyComponent().$mount();
                this.infoWindow.setContent(component.$el)
                this.infoWindow.open(map,point); //信息窗体打开
            },
            oneScreen (camera) { // 切回一分屏
                this.openCameraInfoWindow(camera, camera.dataPosition)
                this.fromFour = true // 是从四分屏切换回来的
                // 播放视频
                this.$nextTick(() => {
                    this.$refs.monitorCameraVideoPlay.play(camera) // 点击相机列表,直接跳出视频播放窗口
                })
            },
            nine2oneScreen(camera) {
                this.openCameraInfoWindow(camera, camera.dataPosition)
                this.fromNine = true // 是从四分屏切换回来的
                // 播放视频
                this.$nextTick(() => {
                    this.$refs.monitorCameraVideoPlay.play(camera) // 点击相机列表,直接跳出视频播放窗口
                })
            },
            showEventTooltip (event) { // 显示事件气泡框
                let that = this
                let MyComponent = Vue.extend({
                    template: `<monitor-event-dialog :eventData="eventData" @changeEventData="changeEventData"></monitor-event-dialog>`,
                    components: {
                        MonitorEventDialog
                    },
                    data () {
                        return {
                            eventData: event
                        }
                    },
                    methods: {
                        changeEventData (eventData) {
                            that.$refs.monitorEvent.changeEventData(eventData)
                        }
                    }
                });
                let component = new MyComponent().$mount();
                this.eventInfoWindow.setContent(component.$el)
                let position = [Number(event.start_lng), Number(event.start_lat)]
                this.eventInfoWindow.open(map, position); //信息窗体打开
            },
            cameraVideoCloseClick(){
                this.isShowCameraVideo = false
                this.$refs.fourScreen.close() // 关闭隐藏的四分屏窗口
                this.$refs.nineScreen.close() // 关闭隐藏的九分屏窗口
            },
            cameraVideoCloseButFourScreenClick () { // 不关闭四分屏
                this.isShowCameraVideo = false
                this.$refs.nineScreen.close() // 关闭隐藏的九分屏窗口
            },
            cameraVideoCloseButNineScreenClick () { // 不关闭九分屏
                this.isShowCameraVideo = false
                this.$refs.fourScreen.close() // 关闭隐藏的四分屏窗口
            },
            layerSelectClick(layerSelectOptions){
                if(layerSelectOptions.layerType === 'camera'){
                    this.cameraLayerClick(layerSelectOptions.selected)
                }else if(layerSelectOptions.layerType === 'etc'){
                    this.etcLayerClick(layerSelectOptions.selected)
                } else if(layerSelectOptions.layerType === 'tollStation'){
                    this.tollStationLayerClick(layerSelectOptions.selected)
                }else if (layerSelectOptions.layerType === 'traffic') {
                    this.trafficSelectClick(layerSelectOptions.selected)
                } else if (layerSelectOptions.layerType === 'event') {
                    this.eventLayerClick(layerSelectOptions.selected)
                } else if (layerSelectOptions.layerType === 'videoEvent') {
                    this.videoEventLayerClick(layerSelectOptions.selected)
                }
            },
            legendSelectClick (checkedData) { // 改变图例选中状态,更改相机可见性
                this.cameraVisible = checkedData
                this.setCameraVisible()
            },
            setCameraVisible () { // 设置相机可见性
                if (this.cameraVisible.sd) {
                    this.cameraTypeLayerGroup.sd.show()
                } else {
                    this.cameraTypeLayerGroup.sd.hide()
                }
                if (this.cameraVisible.sfz) {
                    this.cameraTypeLayerGroup.sfz.show()
                } else {
                    this.cameraTypeLayerGroup.sfz.hide()
                }
                if (this.cameraVisible.fwq) {
                    this.cameraTypeLayerGroup.fwq.show()
                } else {
                    this.cameraTypeLayerGroup.fwq.hide()
                }
                if (this.cameraVisible.wc) {
                    this.cameraTypeLayerGroup.wc.show()
                } else {
                    this.cameraTypeLayerGroup.wc.hide()
                }
                if (this.cameraVisible.mj) {
                    this.cameraTypeLayerGroup.mj.show()
                } else {
                    this.cameraTypeLayerGroup.mj.hide()
                }
            },
            cameraLayerClick(value){
                this.showCamera = value // 全局记录是否展示相机
                if(value){
                    this.cameraLayerGroup.show();
                }else {
                    this.cameraLayerGroup.hide();
                    this.infoWindow.close()
                }
            },
            etcLayerClick(value){
                if(value){
                    this.etcLayerGroup.show();
                }else {
                    this.etcLayerGroup.hide();
                }
            },
            tollStationLayerClick(value){
                if(value){
                    this.tollStationLayerGroup.show();
                }else {
                    this.tollStationLayerGroup.hide();
                }
            },
            eventLayerClick(value){
                if(value){
                    this.eventLayerGroup.show();
                }else {
                    this.eventLayerGroup.hide();
                    this.eventInfoWindow.close()
                }
            },
            videoEventLayerClick(value){
                if(value){
                    this.videoEventLayerGroup.show();
                }else {
                    this.videoEventLayerGroup.hide();
                }
            },
            trafficSelectClick(value){
                if(value){
                    this.trafficLayer.show();
                }else {
                    this.trafficLayer.hide();
                }
            },
            changeCurrentIndustry (industry) { // 切换当前选中的行业(控制图例显示)
                this.currentIndustry = industry
                this.cameraVisible = {  // 将全部相机设置为可见
                    sd: true,
                    sfz: true,
                    wc: true,
                    mj: true,
                    fwq: true
                }
                if (this.$refs.monitorMapCameraLegend !== undefined) {
                    this.$refs.monitorMapCameraLegend.checkedAll()
                }
            },
            resourcesChangeRefreshMap(options){ // 点击具体的 路线/区域/单位之后刷新数据及地图
                this.removeCameraSelectedMarker()
                this.cameraParam = options.cameraParam

                // 删除之前的高亮
                map.remove(this.areaSelectPolygon)

                // 以下的视角移动是由高德函数触发
                this.movedByAmap = true

                // 清除之前的图层
                this.clearCameraLayer()
                if(options.type === RESOURCE_TYPE.高速公路路线.value){
                    let zoom = 8
                    options.cameraParam.zoom = zoom
                    this.getCamera(options.cameraParam, ()=>{
                        if(this.cameraMarkers.length >0){
                            map.setZoom(zoom)
                            // 根据查询出的全部摄像机调整显示范围
                            this.movedByAmap = true
                            map.setFitView(this.cameraMarkers)
                        }
                    })
                }else if(options.type === RESOURCE_TYPE.高速公路区域.value){
                    let zoom = 9 // 地图缩放级别
                    options.cameraParam.zoom = zoom
                    this.getCamera( options.cameraParam, ()=>{

                    })
                    // 高亮行政区域
                    ampUtil.mapDistrictSearch({
                        // 返回行政区边界坐标等具体信息
                        extensions: 'all',
                        // 设置查询行政区级别为 省
                        level: 'city',
                        subdistrict:0
                    },options.queryParam.areaName,(status, result)=>{
                        var holes = result.districtList[0].boundaries;
                        // 高亮市区
                        this.areaSelectPolygon = new AMap.Polygon({
                            map: map,
                            path: holes, //轮廓线的节点坐标数组
                            strokeWeight: 2, // 轮廓线宽度
                            strokeColor: '#256edc', //线条颜色
                            fillOpacity: 0.45, //透明度
                            fillColor: '#0088FF', //填充颜色
                        });
                        // 视角移动到该市区
                        let district = result.districtList[0]
                        map.setZoom(zoom)
                        map.panTo([district.center.lng, district.center.lat], 1)
                    })
                }else if(options.type === RESOURCE_TYPE.高速公路收费所.value){
                    // options.cameraParam.zoom = map.getZoom()
                    let zoom = 12
                    options.cameraParam.zoom = zoom
                    this.getCamera(options.cameraParam, ()=>{
                        if(this.cameraMarkers.length > 0){
                            map.setZoom(zoom)
                            // 根据查询出的全部摄像机调整显示范围
                            this.movedByAmap = true
                            map.setFitView(this.cameraMarkers)
                        }
                    })
                } else if(options.type === 'highway_newroad'){ // 临时-控制新接路段相机查询及视角移动
                    let zoom = 14
                    options.cameraParam.zoom = zoom
                    this.getCamera(options.cameraParam, ()=>{
                        if(this.cameraMarkers.length >0){
                            map.setZoom(zoom)
                            // 根据查询出的全部摄像机调整显示范围
                            this.movedByAmap = true
                            map.setFitView(this.cameraMarkers)
                        }
                    })
                } else if(options.type === RESOURCE_TYPE.国省干线区域.value){
                    // options.cameraParam.zoom = map.getZoom()
                    let zoom = 12
                    options.cameraParam.zoom = zoom
                    this.getCamera(options.cameraParam, ()=>{
                        if(this.cameraMarkers.length > 0){
                            map.setZoom(zoom)
                            // 根据查询出的全部摄像机调整显示范围
                            this.movedByAmap = true
                            map.setFitView(this.cameraMarkers)
                        }
                    })
                } else if (options.type === RESOURCE_TYPE.水运部门.value) {
                    let zoom = 12
                    options.cameraParam.zoom = zoom
                    this.getCamera(options.cameraParam, ()=>{
                        if(this.cameraMarkers.length > 0){
                            map.setZoom(zoom)
                            // 根据查询出的全部摄像机调整显示范围
                            this.movedByAmap = true
                            map.setFitView(this.cameraMarkers)
                        }
                    })
                } else if (options.type === RESOURCE_TYPE.客运区域.value) {
                    let zoom = 9
                    options.cameraParam.zoom = zoom
                    this.getCamera(options.cameraParam, ()=>{
                    })
                    // 高亮行政区域
                    ampUtil.mapDistrictSearch({
                        // 返回行政区边界坐标等具体信息
                        extensions: 'all',
                        // 设置查询行政区级别为 省
                        level: 'city',
                        subdistrict:0
                    },options.queryParam.areaName,(status, result)=>{
                        var holes = result.districtList[0].boundaries;
                        // 高亮市区
                        this.areaSelectPolygon = new AMap.Polygon({
                            map: map,
                            path: holes, //轮廓线的节点坐标数组
                            strokeWeight: 2, // 轮廓线宽度
                            strokeColor: '#256edc', //线条颜色
                            fillOpacity: 0.45, //透明度
                            fillColor: '#0088FF', //填充颜色
                        });
                        // 视角移动到该市区
                        let district = result.districtList[0]
                        map.setZoom(zoom)
                        map.panTo([district.center.lng, district.center.lat], 1)
                    })
                }
                // else {
                //     this.getCamera(options.cameraParam, ()=>{
                //         map.panTo(this.mapCenter, 1)
                //         map.setZoom(this.mapDefaultZoom)
                //     })
                // }
            },
            resourcesAllChangeRefreshMap (options) { // 刷新全部数据
                this.removeCameraSelectedMarker()
                // 删除之前的高亮
                map.remove(this.areaSelectPolygon)
                // map.setZoom(this.mapDefaultZoom)
                this.movedByAmap = true
                this.cameraParam = options.cameraParam
                this.cameraParam.zoom = map.getZoom()
                // 查询范围锁定到甘肃
                this.cameraParam.startLng = 85.902031
                this.cameraParam.endLng = 115.367363
                this.cameraParam.startLat = 29.986922
                this.cameraParam.endLat = 45.099591
                this.getCamera(options.cameraParam, () => {
                    // map.panTo(this.mapCenter, 1)
                })
            },
            clearCameraLayer(){
                // map.remove(this.cameraMarkers)
                // this.cameraMarkers = []
                // map.remove(this.cameraLayerGroup)
                // this.cameraLayerGroup = {}

                for (let item in this.cameraTypeMarkers) {
                    map.remove(this.cameraTypeMarkers[item])
                }
                this.cameraTypeMarkers = {
                    sd: [],
                    sfz: [],
                    wc: [],
                    mj: [],
                    fwq: []
                }
                for (let item in this.cameraTypeLayerGroup) {
                    map.remove(this.cameraTypeLayerGroup[item])
                }
                this.cameraTypeLayerGroup = {
                    sd: {},
                    sfz: {},
                    wc: {},
                    mj: {},
                    fwq: {}
                }
            },
            getCenterPoint(path){
                //由于Polygon的path路径是二位数组
                let data = path[0]
                var lng = 0.0,
                    lat = 0.0;
                for(let i=0; i<data.length; i++){
                    if(this.isEmptyObject(data[i])){continue;}
                    lng = lng + parseFloat(data[i].lng);
                    lat = lat + parseFloat(data[i].lat);
                }
                lng = lng / data.length;
                lat = lat / data.length;
                return [lng, lat];
            },
            isEmptyObject( obj ) {
                var name;
                for ( name in obj ) {
                    return false;
                }
                return true;
            },
            positioningEvent (event) { // 定位事件
                // if (map.getZoom() < 8) {
                    map.setZoom(8)
                // }
                map.panTo([event.start_lng, event.start_lat], 1)
                this.showEventTooltip(event)
            },
            positioningVideoEvent (event) { // 定位事件
                map.setZoom(8)
                let position = [event.longitude, event.latitude]
                map.panTo(position, 1)
                event.cameraName = event.deviceName // 创建相机名称字段
                this.showCameraTooltip(event,position) // 地图点title
                this.$refs.videoEventDetail.open(event) // 事件详情Modal
            },
            setBoundsParam () { // 获取可视范围并封装到查询参数中
                const bounds = map.getBounds()
                this.cameraParam.startLng = bounds.southwest.lng
                this.cameraParam.endLng = bounds.northeast.lng
                this.cameraParam.startLat = bounds.southwest.lat
                this.cameraParam.endLat = bounds.northeast.lat
            }
        },
        beforeMount:function () {
            this.bodyHeight = getExistNavigatorBodyHeignt();
            this.resourceHeight = this.bodyHeight - 40
            this.eventHeight = this.bodyHeight - 600 - 40
        },

        mounted:function () {
            let that = this;
            that.$nextTick(function () {
                window.onresize = () => {
                    return (() => {
                        that.bodyHeight = getExistNavigatorBodyHeignt();
                        that.resourceHeight = that.bodyHeight - 40
                        this.eventHeight = this.bodyHeight - 600 - 40
                        // that.chartHeight = that.bodyHeight - 40
                    })()
                };
                that.mapCreate()
                that.createRoadSignMarker() // 路标指示牌
                that.cameraParam.zoom = map.getZoom()
                that.setBoundsParam()
                that.getCamera(this.cameraParam,null)
                // that.getEtc()
                // that.getTollStation()

                this.$eventBus.$on('addFourScreenVideo', (cameraData) => { // 点击资源面板中的相机,添加四分屏视频功能
                    this.$refs.fourScreen.addVideo(cameraData)
                })

                this.$eventBus.$on('fourScreen', (cameraData) => {
                    if (this.fromFour) { // 是从4分屏切回来的, 点击4分屏,直接打开原先的4分屏面板
                        this.$refs.fourScreen.showWindow()
                    } else { // 不是从四分屏切回来的,打开新的4分屏面板
                        this.$refs.fourScreen.open(cameraData)
                    }
                })

                this.$eventBus.$on('nineScreen', (cameraData) => {
                    if (this.fromNine) { // 是从9分屏切回来的, 点击9分屏,直接打开原先的9分屏面板
                        this.$refs.nineScreen.showWindow()
                    } else { // 不是从9分屏切回来的,打开新的9分屏面板
                        this.$refs.nineScreen.open(cameraData)
                    }
                })

                this.$eventBus.$on('getNearbyCameras', (longitude, latitude, callback) => {
                    this.getNearbyCameras(longitude, latitude, callback)
                })

                this.$eventBus.$on('tourVideoOpen', () => {
                    this.$refs.nineScreenTour.open()
                })

            });
        }
    }
</script>

<style lang="less" >

    /*.marker-selected {*/
    /*    &:after {*/
    /*        content: '台词';*/
    /*        width: 50px;*/
    /*        height: 50px;*/
    /*        background: red;*/
    /*        position: absolute;*/
    /*        top: 10px;*/
    /*        left: 10px;*/
    /*    }*/
    /*}*/

    .marker-selected {
        width: 28px;
        height: 28px;
        /*left: -6px;*/
        /*top: -6px;*/
        border-radius: 14px;
        /*background: #00ffdc;*/
        /*border: 6px solid #00ffdc;;*/
        border: 6px solid #ea575c;;
        position: absolute;
        z-index: 2;

        opacity: .1;
        animation:mymove 4s infinite;
        -webkit-animation:mymove 4s infinite; /*Safari and Chrome*/
        animation-direction:alternate;/*轮流反向播放动画。*/
        animation-timing-function: ease-in-out; /*动画的速度曲线*/
        /* Safari 和 Chrome */
        -webkit-animation:mymove 4s infinite;
        -webkit-animation-direction:alternate;/*轮流反向播放动画。*/
        -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
    }

    @keyframes mymove
    {
        0%{
            transform: scale(.5);  /*开始为原始大小*/
            opacity: .1;
        }
        25%{
            transform: scale(1); /*放大1.1倍*/
            opacity: 1;
        }
        50%{
            transform: scale(.5);
            opacity: .1;
        }
        75%{
            transform: scale(1);
            opacity: 1;
        }

    }

</style>
